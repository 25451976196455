import React from 'react'
import { Parallax } from 'react-parallax'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import { mobilePromotion, promotions } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={mobilePromotion} w="100%" alt="Promotions" mt="15px" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center m="0 auto" maxWidth="1400px">
          <CFImage
            src={promotions}
            w="100%"
            maxWidth="1400px"
            mb="30px"
            alt="Promotions"
          />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
