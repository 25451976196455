import React from 'react'
import {
  CFView,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import {
  downloadAppButton,
  hero,
  heroText,
  mobileDownloadButton,
  mobilePickupButton,
  mobileHero,
  mobileHeroText,
} from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) center / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyEnd
          alignCenter
        >
          <CFView mb="12vh">
            <a
              href={
                'https://order.codefusion.tech/restaurants/KnihnbrmAItDVI5mA2Md/locations/KjUpAyRxIr5vhZ8KEKsn'
              }
            >
              <CFView hover mb="5px">
                <CFImage src={mobilePickupButton} w="76vw" alt="About" />
              </CFView>
            </a>
            <a href={'http://onelink.to/6298wt'}>
              <CFView hover>
                <CFImage src={mobileDownloadButton} w="76vw" alt="About" />
              </CFView>
            </a>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          w="100%"
          maxHeight="690px"
          image={`url(${hero}) center / cover no-repeat`}
          boxShadow="0 2px 5px rgba(0,0,0,.5)"
          zIndex={90}
          column
          justifyBetween
        >
          <Header />
          <CFView column alignStart>
            <CFView ml="3%">
              <CFImage src={heroText} maxWidth="780px" alt="About" />
            </CFView>
            <CFView mb="3%" ml="3%" row center>
              <CFView pr="20px">
                <OrderPickupButton />
              </CFView>
              <CFLink href={'http://onelink.to/6298wt'} newTab>
                <CFView hover>
                  <CFImage
                    src={downloadAppButton}
                    maxWidth="350px"
                    alt="About"
                  />
                </CFView>
              </CFLink>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
